@import '../../../../styles/all.scss';

.c-PreCheckoutAsLoggedInUserForm {
  &__header {
    @include fontTitle(22px, $dark-text);
    margin-bottom: 24px;
  }

  &__signup-with-email {
    margin-top: 16px;
    @include fontRegularCMC(14px, #666);
    text-align: center;

    span {
      font-weight: 600;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
