@import '../../../styles/all.scss';

@keyframes modal-slide-up {
  from {
    top: calc(50% + 30px);
    opacity: 0;
  }
  to {
    top: 50%;
    opacity: 1;
  }
}

.c-Modal {
  display: none;

  &--open {
    display: block;
    z-index: 1000;
  }

  &__backdrop {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.82);
  }

  &__container {
    position: fixed;
    width: 80%;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background-color: white;
    border-radius: 28px;
    box-sizing: border-box;
    animation: modal-slide-up 0.3s ease-out;
    max-height: min(768px, 80vh);

    &__no-gutter {
      padding: 0;
    }
  }

  &__inner {
    overflow-y: auto;
    height: 100%;
    padding: 24px 16px;

    @include for-desktop {
      padding: 32px;
    }

    &__no-gutter {
      padding: 0;
    }
  }
  &__eyebrow {
    align-self: flex-start;
    border-radius: 28px;
    width: 100%;
  }

  &__chin {
    align-self: flex-end;
    width: 100%;
    border-radius: 28px;
  }

  &__transparent_white_color {
    background: transparent;
    width: 90%;
  }

  &__left-icon {
    cursor: pointer;
    position: absolute;
    top: 8px;
    left: 8px;
  }

  &__close-modal {
    cursor: pointer;
    position: absolute;
    width: 40px;
    height: 40px;
    right: 8px;
    top: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    transition: all 0.2s ease-in-out;
    z-index: 2;
    &:hover {
      background: rgba(25, 28, 30, 0.08);
    }
  }

  &__outside-close-modal {
    top: 24px !important;
    right: 24px !important;
  }

  /* Tooltip text */
  &__tooltip &__tooltiptext {
    visibility: hidden;
    width: 200px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    // Position Right
    top: -5px;
    left: 105%;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1001;
  }
  &__tooltip:hover &__tooltiptext {
    visibility: visible;
  }
  &--animated {
    animation: 0.3s ease-in-out;
  }

  @keyframes slideIn {
    0% {
      opacity: 0;
      transform: translateY(-100px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
