@import '../../../../styles/all.scss';

.c-Input {
  margin: 8px 0;
  font-family: $font-poppin;

  &__container {
    position: relative;

    &__icon {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translate(0, -50%);
      cursor: pointer;

      .c-EyeVisible {
        display: block;
      }
    }

    &__icon_left {
      position: absolute;
      left: 16px;
      top: 50%;
      transform: translate(0, -50%);
      cursor: pointer;

      .c-EyeVisible {
        display: block;
      }
    }

    input {
      @include fontRegular(16px, $dark-text);
      width: 100%;
      border: none;
      border-radius: 12px;
      box-sizing: border-box;
      padding: 16px;
      background-color: $form-el-bg-color;
      font-family: $font-poppin;
      color: $dark-text;
      font-size: 16px;

      &:disabled {
        cursor: not-allowed;
      }

      &::placeholder {
        font-family: $font-poppin;
      }
    }
  }

  &--error {
    input {
      background-color: $form-el-error-bg-color;
    }
  }

  &__rounded {
    input {
      border-radius: 12px !important;
    }
  }
  &__error {
    font-size: 12px;
    color: $danger-color;
    margin: 8px;
  }

  &--v2 {
    label {
      display: block;
      margin-bottom: 8px;
      @include fontRegularCMC(14px, #666);
      font-weight: 500;
    }

    input {
      height: 48px;
      color: #333;
      padding: 16px;
      font-family: $font-poppin;
      background-color: transparent;
      border-radius: 4px;
      border: 1px solid $disabled-button-bg-color;

      &:disabled {
        cursor: not-allowed;
      }

      &:focus {
        border: 1px solid #666;
      }

      ::-webkit-input-placeholder {
        /* Edge */
        color: #aaa;
        font-family: $font-poppin;
      }

      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        font-family: $font-poppin;
        color: #aaa;
      }

      ::placeholder {
        font-family: $font-poppin;
        color: #aaa;
      }
    }

    &--error {
      input {
        border: 1px solid #db3952;
      }
    }

    & .c-Input__error {
      margin: 0;
      margin-top: 8px;

      @include fontRegularCMC(14px, #db3952);
    }

    &--floating-label {
      label {
        transform: translate(0, 2.75rem);
      }
      @supports (not (-ms-ime-align: auto)) {
        label {
          color: #999;
          transform: translate(0.25rem, -1.5rem);
          transition: all 0.2s ease-out;
        }

        input:focus + label,
        input:not(:placeholder-shown) + label {
          color: #111;
          transform: translate(0, 2.75rem);
        }
      }
    }
  }

  &--stacked {
    label {
      display: block;
      margin-bottom: 8px;
      /* Label/lg/medium */

      font-family: $font-poppin;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      /* identical to box height, or 125% */

      /* Text Colour/Light Surface/Primary */

      color: #191c1e;
    }

    input {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 16px;
      gap: 16px;

      /* New Neutral/Neutral 100 */

      background: #ffffff;
      /* New Neutral/Neutral 80 */

      border: 1px solid #dbdbd7;
      border-radius: 12px;

      /* Label/lg/medium */

      font-family: $font-poppin;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      /* identical to box height, or 150% */

      &--error {
        border: 1px solid #db3952;
      }
      &:disabled {
        cursor: not-allowed;
      }

      &:hover {
        border: 1px solid #666;
      }

      &:focus {
        border: 2px solid #7486ff;
      }

      ::-webkit-input-placeholder,
      :-ms-input-placeholder,
      ::placeholder {
        color: #75777a;
      }

      transition: color 0.2s ease-out, border 0.2s ease-out;
    }
  }

  &--floating-label {
    &__container {
      position: relative;
      label {
        display: block;
        transform: translate(0, -2.75rem);
      }
    }
  }
}
