@import 'styles/all';

.emailWrapper {
  text-align: center;
  :global {
    .c-Button {
      @include for-small-desktop {
        width: 198px;
        border-radius: 4px;
      }
    }

    .c-Input {
      input {
        // width: 320px;
        height: 48px;
        border: 1px solid #75777a;
        background-color: transparent;
        border-radius: 4px;
      }
    }

    .c-EmailForm {
      button {
        border-radius: 4px;
        width: 145px;
        padding: 12px;
        @include fontTitleCMC(16px, #191c1e);
      }
    }
  }
}

.faq {
  :global {
    .c-FaqAccordian {
      background-color: transparent;
    }
  }
}
