@import '../../../styles/all.scss';

.c-ToolTip {
  position: relative;
  z-index: 100;

  &__content {
    z-index: 1200;
    position: absolute;

    &--bottom {
      top: calc(100% + 8px);
      left: 50%;
      transform: translateX(-50%);
    }

    &--right {
      top: 50%;
      left: calc(100% + 8px);
      transform: translateY(-50%);
    }

    &--top {
      top: -8px;
      left: 50%;
      transform: translate(-50%, -100%);
    }

    &--left {
      top: 50%;
      left: -8px;
      transform: translate(-100%, -50%);
    }
  }

  &__arrow {
    position: absolute;

    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;

    border-bottom: 5px solid #2c2c2c;

    &--bottom {
      top: 0;
      left: 50%;
      transform: translate(-50%, -100%);
    }

    &--right {
      top: 50%;
      left: 0;
      transform: rotate(-90deg) translate(25%, -150%);
    }

    &--top {
      top: 100%;
      left: 50%;
      transform: rotate(180deg) translate(50%, 0%);
    }

    &--left {
      top: 50%;
      left: 100%;
      transform: rotate(90deg) translate(-25%, 50%);
    }
  }
}
