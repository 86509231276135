@import '../../../../styles/all.scss';

.c-Select {
  @include fontRegular(16px, $dark-text);

  &__label {
    margin: 16px 0;
    font-weight: 700;
    display: block;
  }

  &__container {
    select {
      @include fontRegularCMC(16px, $dark-text);
      border: none;
      border-radius: 8px;
      padding: 16px;
      background: url('https://d2oi1rqwb0pj00.cloudfront.net/na-website/svg/chevron-down.svg')
        no-repeat 95% $form-el-bg-color;
      -webkit-appearance: none;
      appearance: none;
      width: 100%;
      margin-right: 12px;

      &::-ms-expand {
        display: none;
      }

      &:invalid {
        color: $alt-text-color;
      }
    }
  }

  &--error {
    select {
      background-color: $form-el-error-bg-color;
    }
  }

  &--show-placeholder {
    select {
      color: $alt-text-color;
    }
  }

  &__error {
    @include fontRegular(16px, $danger-color);
    color: $danger-color;
    margin: 8px 0;
  }

  &--dark {
    select {
      background: #2c2c2c;
    }
  }
  &--v2 {
    label {
      display: block;
      margin-bottom: 8px;
      font-size: 14px;
      font-family: 'Poppins', sans-serif;
      color: #666;
      font-weight: 500;
    }
    select {
      padding: 11px;
      background-color: #fff;
      border: 1px solid #e0e0e0;
      &:hover {
        border: 1px solid #666;
      }
    }
  }
}
