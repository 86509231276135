@import '../../../../styles/all';

.c-CheckmarkIcon {
  width: 32px;
  height: 32px;

  path,
  ellipse {
    fill: #50dc5b;
  }

  &--fill-primary {
    path,
    ellipse {
      fill: $primary-color;
    }
  }
}
