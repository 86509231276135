@import '../../../styles/all.scss';

.c-Card {
  border-radius: 8px;
  box-shadow: 0px 3px 28px rgba(0, 0, 0, 0.08);
  padding: 16px;

  &--light {
    background-color: #fff;
  }

  &--dark {
    background-color: $dark-color;
  }

  &--success {
    background-color: $success-bg-color;
    color: $success-text-color;
    font-weight: 700;
  }

  &--error {
    background-color: $form-el-error-bg-color;
    color: $danger-color;
    font-weight: 700;
  }

  &--text-center {
    text-align: center;
  }
}
