.c-WhatsappSignUpPopup {
  :global {
    .c-FloatingLabelInput {
      max-height: 58px;

      input:placeholder-shown + label {
        position: absolute;
        bottom: -18px;
        left: 0px;
        cursor: text;
        max-width: 75%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transform-origin: left bottom;
        transform: translate(8px, -35px);
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
      }

      input:not(:placeholder-shown) + label,
      input:focus + label {
        transform: translate(8px, -52px);

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;

        color: #75777a;
        cursor: pointer;
      }

      input[type='number']::-webkit-inner-spin-button,
      input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
      }
    }
  }
}
