@import '../../../../../styles/all.scss';

.c-LoginForm {
  &__or {
    position: relative;
    margin: 24px 0;
    text-align: center;
    height: 12px;

    &::after {
      position: absolute;
      content: '';
      width: 100%;
      height: 1px;
      background-color: $disabled-button-bg-color;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
    }

    &__text {
      @include fontRegularCMC(12px, #aaa);
      padding: 0 12px;
      background-color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;

    &__input__wrapper {
      position: relative;
      width: 100%;
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 12px;
      }

      :global {
        .c-Input {
          margin: 0;
        }
      }
    }

    &__input {
      @include fontRegular(16px, $alt-text-color);
      box-sizing: border-box;
      background-color: $alt-section-bg-color;
      padding: 16px;
      outline: none;
      border: none;
      border-radius: 8px;
      width: 100%;
    }

    &__icon {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      right: 12px;
      cursor: pointer;
    }

    &__link {
      @include fontRegularCMC(14px, #666);
      font-weight: 500;
      text-align: right;
      text-decoration: underline;
    }

    &__required {
      color: red;
    }

    &__margin-none {
      :global {
        .c-Input {
          margin: 0px;
        }
      }
    }

    :global {
      .c-Button {
        margin-top: 24px;
      }

      .c-OneOnOneCard {
        margin-bottom: 0;
      }

      .c-Select {
        background-color: $form-select-v2-bg-color;
        select {
          max-width: 100px;
        }
      }
    }
  }

  &__message {
    box-sizing: border-box;
    width: 100%;
    padding: 16px;
    border-radius: 8px;
    margin-top: 16px;
    margin-bottom: 16px;

    &--error {
      @include fontRegular(16px, $danger-color);
      font-family: $font-poppin;
      background-color: #ffebef;
    }

    &--loading {
      @include fontRegular(16px, $primary-color);
      font-family: $font-poppin;
      background-color: $primary-color-light;
    }
  }
}
