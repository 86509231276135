@import 'styles/all';

.emailWrapper {
  text-align: center;
  :global {
    .c-Button {
      @include for-small-desktop {
        width: 198px;
        border-radius: 4px;
      }
    }

    .c-Input {
      input {
        // width: 320px;
        height: 48px;
        border: 1px solid #75777a;
        background-color: transparent;
        border-radius: 4px;
      }
    }

    .c-EmailForm {
      button {
        border-radius: 4px;
        width: 145px;
        padding: 12px;
        @include fontTitleCMC(16px, #191c1e);
      }
    }
  }
}

.faq {
  :global {
    .c-FaqAccordian {
      background-color: transparent;
    }
  }
}

.dismiss-card {
  transform: translateX(300px);
  transition: all 0.6s ease-out;
}

// Hexagon
.color-0 {
  background-color: rgba(240, 240, 255, 1);

  color: rgba(52, 66, 178, 1);
}

.hex-text-0 {
  color: rgba(52, 66, 178, 1);
}

// Hexagon
.color-1 {
  background-color: #e6f9ff;

  color: #007aa3;
}

// Hexagon
.color-2 {
  background-color: rgba(255, 240, 229, 1);

  color: rgba(189, 75, 0, 1);
}

// Hexagon
.color-3 {
  background-color: rgba(238, 250, 219, 1);

  color: rgba(93, 119, 13, 1);
}

// Hexagon
.color-4 {
  background-color: rgba(254, 236, 247, 1);

  color: rgba(205, 29, 141, 1);
}

.hexagon {
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  height: 48px;
  width: 44px;
  min-width: 44px;
  position: relative;
}
