@import '../../../../styles/all.scss';

.c-PreCheckoutStep {
  &__modal-container {
    box-sizing: border-box;
    // max-width: 328px;
    // width: 328px;
    max-width: 382px;
    min-width: min(99%, 328px);

    @include for-tablet {
      max-width: 500px;
      width: 500px;
    }

    :global {
      .c-Modal__left-icon {
        top: 16px;
        left: 24px;
      }

      .c-Modal__close-modal {
        top: 16px;
        right: 24px;
      }

      // .c-SocialLogins {
      //   background-color: pink;
      // }

      @include for-tablet {
        .c-SocialLogins
          .c-SocialLogins__social-btn-wrapper--google
          .c-Button,
        .c-SocialLogins
          .c-SocialLogins__social-btn-wrapper--facebook
          .c-Button,
        .c-SocialLogins
          .c-SocialLogins__social-btn-wrapper--apple
          .c-Button {
          &::before {
            margin-left: 94px;
          }
        }
      }
    }
  }

  @media screen and (max-height: 767px) {
    &__modal-container {
      overflow-y: scroll;
    }
  }

  &__separator {
    background: #f2f2f2;
    height: 2px;
    width: 100%;
    margin: 24px 0 12px 0;
  }

  &__term-and-condition {
    @include fontRegularCMC(12px, #aaa);
    margin: 12px auto auto auto;
    text-align: center;

    a {
      text-decoration: underline;
      font-weight: 500;
    }
  }

  &__loading_text {
    @include fontRegularCMC(14px, #666);
    margin: 12px auto auto auto;
    text-align: center;
  }

  &__modal {
    :global {
      .c-CheckoutErrorPrompt {
        .c-Prompt__container {
          border-radius: 8px;
          top: 0;
          bottom: initial;

          @include for-desktop {
            top: -50%;
          }
        }
      }
    }
  }

  :global {
    .c-SocialLogins {
      background-color: pink;
    }
    .c-SocialLogins .c-SocialLogins__social-btn-wrapper--google .c-Button {
      &::before {
        margin-left: 100px;
      }
    }
  }
}
