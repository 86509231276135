@import 'styles/all';

.text {
  @include fontRegularCMC(14px, #75777a);
  text-align: center;
  line-height: 150%;
}

.modal {
  max-width: 500px;
}
