@import '../../../../styles/all.scss';

.c-Checkbox {
  @include fontRegular(16px, $dark-text);
  line-height: 24px;
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &__checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: $form-btn-secondary-bg-color;
    border-radius: 8px;

    &::after {
      content: ' ';
      position: absolute;
      display: none;
    }

    &--checked {
      background-color: $primary-color;

      &::after {
        content: ' ';
        position: absolute;
        display: block;
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid black;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }

  &__error {
    @include fontRegular(12px, $danger-color);
    margin: 8px 0;
  }
}
