@import '../../../styles/all.scss';


.sucess-toast{
    height: 40px;
    border-radius: 12px;
    background-color: #191C1E;
    color: #FBFCFF;
    z-index: 100;
    padding: 15px;
    display: grid;
    align-content: center;
    padding: 28px 17px;
    font-family: Poppins;
    animation: translateUp 3s ease-in-out forwards;
  }

@keyframes translateUp {
  
  0% {
    bottom: 0px;
    opacity: 0;
  }
  25% {
    bottom: 60px;
    opacity: 1;
  }




}
.sucess-toast.top {
  top: 100px;
}

.sucess-toast.right {
  right: 8px;
}
.sucess-toast.left{
    left:8px;
}

.sucess-toast.bottom {
  bottom: 50px;
}
.succes-toast.centerhorizontal{ 
    left: 50%;
    width: fit-content;
    transform: translateX(-50%);
}
