@keyframes animate-visible {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes animate-hidden {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}

.visible {
  animation: animate-visible 0.3s ease-in-out forwards;
}
.hidden {
  animation: animate-hidden 0.3s ease-in-out forwards;
}
