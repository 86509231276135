@import '../../../../styles/all.scss';

.c-CheckoutErrorPrompt {
  &__title {
    margin: 20px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__h4 {
    font-family: $font-title;
    margin: 0;
  }

  &__p {
    font-family: $font-regular;
    color: $form-btn-secondary-alt-text-color;
    font-size: 12px;
    margin: 10px;
    text-align: center;

    @include for-desktop {
      max-width: 328px;
      font-size: 16px;
    }
  }

  &__empty {
    display: flex;
    justify-content: center;

    svg {
      width: 160px;
      height: 120px;
      margin: 20px 20px 10px;
      cursor: pointer;
    }
  }
}
