@import '../../../../styles/all.scss';

.c-RadioSelect {
  // input {
  //   margin-bottom: 18px;
  // }

  &__radio-label {
    // @include fontRegularCMC(16px, $dark-text);
    margin-left: 8px;
    cursor: pointer;
  }

  &__label {
    display: block;
    margin-bottom: 8px;
    @include fontRegularCMC(16px, #1b1b18);
    font-weight: 500;
  }

  &__error {
    @include fontRegular(12px, $danger-color);
    margin: 8px 16px;
  }

  &__input-wrapper {
    display: flex;
    align-items: center;
  }
}
