@import '../../../../styles/all.scss';

.c-EmailForm {
  &__form-container {
    :global {
      .c-Input {
        margin-top: 0;
        margin-right: 14px;
        flex: 1;
      }
    }
    margin-top: 0px !important;
    @include for-desktop {
      display: flex;
    }
  }
  @keyframes autofill {
    0%,
    100% {
      color: #666;
      background: transparent;
    }
  }

  &__input_with_transparent:-webkit-autofill,
  &__input_with_transparent:-webkit-autofill:hover,
  &__input_with_transparent:-webkit-autofill:focus {
    -webkit-text-fill-color: #f2f2f2;
    box-shadow: 0 0 0px 1000px #2c2c2c inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  // change the font of placeholder to font poppins
  &__input_with_transparent::-webkit-input-placeholder {
    font-family: Poppins;
    font-size: 16px;
  }

  &__input_with_transparent:-webkit-autofill,
  &__input_with_transparent:-webkit-autofill:hover,
  &__input_with_transparent:-webkit-autofill:focus {
    animation-delay: 1s;
    animation-name: autofill;
    animation-fill-mode: both;
    font-family: Poppins;
  }
  &__select_with_transparent_error {
    background: transparent !important;
    border: 1px solid #db3952 !important;
    color: white !important;
    font-family: Poppins !important;
    width: 65px !important;
    option:not(:checked) {
      margin: 40px;
      background: #2c2c2c !important;
      color: #fff !important;
      text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4) !important;
      width: 65px !important;
    }
    option:checked {
      margin: 40px;
      background: #2c2c2c !important;
      color: #fff !important;
      text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4) !important;
    }
  }

  &__select_with_transparent {
    background: transparent !important;
    border: 1px solid #75777a !important;
    color: white !important;
    font-family: Poppins !important;
    width: 65px !important;
    option:not(:checked) {
      margin: 40px;
      background: #2c2c2c !important;
      color: #fff !important;
      text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4) !important;
      width: 65px !important;
    }
    option:checked {
      margin: 40px;
      background: #2c2c2c !important;
      color: #fff !important;
      text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4) !important;
    }
  }
  &__button-width-style {
    @include for-mobile {
      width: 100% !important;
    }
  }
  &__input_error_transparent {
    text-align: left;
    margin-left: 0px;
    font-size: 14px;
  }

  &__input_with_error {
    border-color: #db3952 !important;
  }

  &__input_with_transparent {
    color: white !important;
    font-family: Poppins;
  }

  &__input_with_transparent[type='text'] {
    font-family: Poppins;
  }
  &__form-container-direction {
    flex-direction: column;
  }

  &__submit-btn-container {
    :global {
      .c-Button {
        border-radius: 12px;
      }
    }
  }

  :global {
    .c-Card--success {
      display: flex;
      align-items: center;

      .c-Text {
        @include fontCardDescription();
        font-weight: 400;
        line-height: 24px;
        color: $dark-text;
        margin: 0;
      }
    }
  }
}
