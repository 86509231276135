@import '../../../styles/all.scss';

.c-Text {
  color: #1b1b1b;

  &--highlighted {
    color: $primary-color;
  }

  &--underlined {
    text-decoration: underline;
  }

  &--bolder {
    font-weight: 700;
  }

  &--semibold {
    font-weight: 600;
  }

  &--newline {
    display: block;
    &::before {
      content: '\A';
      white-space: pre;
    }
  }

  &--new-paragraph {
    display: block;
    margin-top: 18px;

    @include for-desktop {
      margin-top: 24px;
    }
  }

  &--anchor {
    font-weight: 700;
    color: $primary-color;
    cursor: pointer;
    text-decoration: underline;
  }

  &--striked {
    text-decoration: line-through;
  }

  &--link {
    text-decoration: underline;
    color: #00aff4;
  }
}
