@import 'styles/all';

.c-LoadingCircle {
  margin: 60px auto;
  font-size: 15px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.5em solid rgba(251, 201, 27, 0.2);
  border-right: 0.5em solid rgba(251, 201, 27, 0.2);
  border-bottom: 0.5em solid rgba(251, 201, 27, 0.2);
  border-left: 0.5em solid #fbc91b;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;

  border-radius: 50%;

  &:after {
    border-radius: 50%;
    width: 5em;
    height: 5em;
  }
}

.full-screen {
  position: fixed;
  display: flex;
  z-index: 19;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: $form-payment-el-light-bg;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
