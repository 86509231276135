.c-Prompt {
  &__container {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: auto;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #fff;
    padding: 16px;
    z-index: 101; // 1 more than Backdrop z-index
  }

  &__close {
    position: absolute;
    width: 32px;
    height: 32px;
    opacity: 0.5;
    z-index: 100;
    top: 8px;
    right: 10px;

    &:hover {
      opacity: 1.2;
      cursor: pointer;

      &::before,
      &::after {
        opacity: 0.5;
        cursor: pointer;
      }
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }

    &::before,
    &::after {
      position: absolute;
      top: 8px;
      left: 15px;
      content: ' ';
      height: 22px;
      width: 2px;
      background-color: white;
      color: white;
    }
  }
}
