@import "../../../../styles/all.scss";

.c-PreCheckoutWithEmailForm {
  &__header {
    @include fontTitle(22px, $dark-text);
    margin-bottom: 24px;
  }

  &__label {
    @include fontRegularCMC(14px, #666);
    margin: 16px auto auto auto;
    text-align: center;

    &__login {
      text-decoration: underline;
      font-weight: 500;
      cursor: pointer;
    }
  }

  :global {
    .c-FormField {
      margin-bottom: 24px;
    }

    .c-Select {
      background-color: $form-select-v2-bg-color;

      select {
        max-width: 100px;
      }
    }
  }

  &__or {
    position: relative;
    margin: 24px 0;
    text-align: center;
    height: 12px;

    &::after {
      position: absolute;
      content: "";
      width: 100%;
      height: 1px;
      background-color: $disabled-button-bg-color;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
    }

    &__text {
      @include fontRegularCMC(12px, #aaa);
      padding: 0 12px;
      background-color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
    }
  }
  &__form-required {
    color: red;
  }
}
